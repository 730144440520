<template>
  <div>
    <div class="bg-white py-8 px-16 flex items-center">
      <div class="w-1/2">
        <img :src="eIm" class="w-full" alt="" />
      </div>
      <div class="w-1/2 flex items-center">
        <div class="pl-8 font-medium w-full items-between">
          <p>Event Name</p>
          <h2 class="text-2xl font-semibold">{{eTt}}</h2>
          <p class="mt-3">Date</p>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-regular fa-calendar" size="lg" />
            </span>{{$moment(eDt).format("dddd, D MMMM YYYY")}}</p>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-regular fa-clock" size="lg" />
            </span>{{eTm}} WIB</p>
          <p class="mt-3">Location</p>
          <p><span class="mr-2">
              <font-awesome-icon icon="fa-solid fa-location" size="lg" />
            </span>{{eLc}}</p>
          <div class="flex justify-between mt-3">
            <div class="flex items-center group cursor-pointer" @click="openDetailEvent(eLi)">
              <button href="#" class="text-sm">Explore more</button><span class="ml-4 flex items-center text-primary">
                <font-awesome-icon icon="fa-solid fa-arrow-right" size="lg" />
              </span>
            </div>
            <button class="text-white py-2 px-12 rounded-full bg-primary hover:bg-primary-darker"
              @click="checkRegister(eLi)">Join Event</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardEventList',
  props: {
    eIm: {
      type: String,
      required: true,
    },
    eTt: {
      type: String,
      required: true,
    },
    eDt: {
      type: String,
      required: true,
    },
    eTm: {
      type: String,
      required: true,
    },
    eLc: {
      type: String,
      required: true,
    },
    eLi: {
      type: Number,
      required: true,
    },
    eLk: {
      type: Number,
      required: true,
    },
    eLn: {
      type: String,
      required: false,
    },
  },
  components: {
  },
  methods: {
    openDetailEvent(id) {
      this.$router.push({
        name: 'EventDetail',
        params: { id: id }
      });
    },
    async checkRegister(id) {
      if (this.eLk === 1) {
        window.open(this.eLn, '_blank')
        return false
      }
      const checkUser = this.$store.state.user.telepon
      if (!checkUser) {
        this.$router.push({ name: "Login" })
        return false
      }
      try {
        const res = await this.$http.post(`${this.$apiTripweWeb}/event/check-register`, {
          txtTelepon: this.$store.state.user.telepon,
          txtIdEvent: id
        })
        if (res.data.status === 200) {
          this.$toasted.global.success("You are already registered");
        } else {
          this.$router.push({ name: "EventRegistration" });
        }
      } catch (error) {
        this.$toasted.global.error(error);
      }
    }
  }
};
</script>
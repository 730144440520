var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-full grid h-96 justify-item-center font-extrabold text-white text-center content-center",style:({
      backgroundImage: 'url(/bg_js.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backdropFilter: 'blur(10px)',
  })},[_c('h1',{staticClass:"text-8xl"},[_vm._v("Event Tripwe")])]),_c('div',{staticClass:"container max-w-screen-lg item-center mx-auto bg-grey-1",attrs:{"id":"news"}},[(!_vm.loadingRecent)?_c('div',{},[( _vm.recents===undefined ||
      _vm.recents.length===0)?_c('div',{staticClass:"bg-white py-8 px-16 flex items-center justify-center"},[_c('h3',{staticClass:"font-medium"},[_vm._v("No event available")])]):_vm._l((_vm.recents),function(data){return _c('div',{key:data.id_event},[_c('CardEventList',{attrs:{"eTt":data.judul_event,"eDt":data.tanggal_event,"eTm":data.jam_event,"eLc":data.alamat_event,"eIm":data.foto_event,"eLi":data.id_event,"eLk":data.khusus_linke,"eLn":data.linke}})],1)})],2):_c('div',{},[_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white py-8 px-16 flex items-center"},[_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"rounded-10 h-64 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1"})]),_c('div',{staticClass:"w-1/2 flex items-center"},[_c('div',{staticClass:"pl-8 font-medium w-full items-between"},[_c('div',{staticClass:"rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1"}),_c('div',{staticClass:"rounded-10 flex flex-row justify-between p-3 h-10 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1"}),_c('div',{staticClass:"rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1"}),_c('div',{staticClass:"rounded-10 flex flex-row justify-between p-3 h-10 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1"}),_c('div',{staticClass:"rounded-10 flex flex-row justify-between p-3 h-10 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1"}),_c('div',{staticClass:"rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1"}),_c('div',{staticClass:"rounded-10 flex flex-row justify-between p-3 h-10 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1"}),_c('div',{staticClass:"flex justify-between mt-3"},[_c('div',{staticClass:"flex items-center group"},[_c('div',{staticClass:"rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1"})]),_c('div',{staticClass:"rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1"})])])])])
}]

export { render, staticRenderFns }
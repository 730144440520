<template>
  <div>
    <div class="w-full grid h-96 justify-item-center font-extrabold text-white text-center content-center" :style="{
        backgroundImage: 'url(/bg_js.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backdropFilter: 'blur(10px)',
    }">
      <h1 class="text-8xl">Event Tripwe</h1>
    </div>
    <div class="container max-w-screen-lg item-center mx-auto bg-grey-1" id="news">
      <div class="" v-if="!loadingRecent">
        <div class="bg-white py-8 px-16 flex items-center justify-center" v-if=" recents===undefined ||
        recents.length===0">
          <h3 class="font-medium">No event available</h3>
        </div>
        <div v-else v-for="data in recents" :key="data.id_event">
          <CardEventList :eTt="data.judul_event" :eDt="data.tanggal_event" :eTm="data.jam_event"
            :eLc="data.alamat_event" :eIm="data.foto_event" :eLi="data.id_event" :eLk="data.khusus_linke" :eLn="data.linke" />
        </div>
      </div>
      <div class="" v-else>
        <div class="bg-white py-8 px-16 flex items-center">
          <div class="w-1/2">
            <div class="rounded-10 h-64 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1"></div>
          </div>
          <div class="w-1/2 flex items-center">
            <div class="pl-8 font-medium w-full items-between">
              <div
                class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
              </div>
              <div
                class="rounded-10 flex flex-row justify-between p-3 h-10 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1">
              </div>
              <div
                class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
              </div>
              <div
                class="rounded-10 flex flex-row justify-between p-3 h-10 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1">
              </div>
              <div
                class="rounded-10 flex flex-row justify-between p-3 h-10 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1">
              </div>
              <div
                class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
              </div>
              <div
                class="rounded-10 flex flex-row justify-between p-3 h-10 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse my-1">
              </div>
              <div class="flex justify-between mt-3">
                <div class="flex items-center group">
                  <div
                    class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
                  </div>
                </div>
                <div
                  class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse my-1">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardEventList from '../../components/CardEventList';

export default {
  name: 'EventList',
  components: {
    CardEventList
  },
  data: () => ({
    loadingRecent: false,
    recents: [],
  }),
  methods: {
    async getEvent() {
      this.loadingRecent = true;
      try {
        const res = await this.$http.get(`${this.$apiTripweWeb}/event/list`)
        const data = res.data.response
        if (data === undefined || data.length === 0) {
          this.$toasted.global.success("No event available");
        } else {
          this.recents = data
        }
      } catch (error) {
        this.$toasted.global.error("Fail to load event");
      } finally {
        this.loadingRecent = false;
      }
    },
  },
  created() {
    this.getEvent()
  }
};
</script>
